import { captureException } from "@sentry/nextjs";
import { useState } from "react";

import { BrokerContext } from "@/utils/types/BrokerContext";

import "isomorphic-fetch";

import usePrepareOrderGQL from "./graphql/usePrepareOrderGQL";
import { ProductType, VendorAddress } from "./BackendTypes";

const useBackendBrokerContext = (): BrokerContext => {
  const [orderId, setOrderId] = useState("");

  const [products, setProducts] = useState<ProductType[]>([]);

  const usePrepareOrder = (): {
    prepareOrderL: (
      quoteId: string,
      orderNumber: string,
      origin: VendorAddress,
      destination: VendorAddress
    ) => void;
  } => {
    const [prepareOrder] = usePrepareOrderGQL();

    const prepareOrderL = async (
      quoteId: string,
      orderNumber: string,
      origin: VendorAddress,
      destination: VendorAddress
    ) => {
      try {
        const response = await prepareOrder(
          quoteId,
          orderNumber,
          origin,
          destination
        );
        if (response && !response.error) {
          setOrderId(response.vendorOrder.id);
        }
      } catch (e) {
        captureException(e);
      }
    };
    return { prepareOrderL };
  };

  const setProduct = (product: ProductType) => {
    products.push(product);
    return { products };
  };

  const resetOrderId = () => {
    setOrderId("");
  };

  const brokerContext: BrokerContext = {
    usePrepareOrder,
    setProduct,
    orderId,
    products,
    resetOrderId,
  };

  return brokerContext;
};

export default useBackendBrokerContext;
