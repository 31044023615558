import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const NEXT_PUBLIC_BROKER_API_KEY = process.env.NEXT_PUBLIC_BROKER_API_KEY || '';

const NEXT_PUBLIC_NAUTILUS_BROKER_GRAPHQL_ENDPOINT =
  process.env.NEXT_PUBLIC_NAUTILUS_BROKER_GRAPHQL_ENDPOINT;


const httpLink = createHttpLink({
  uri: NEXT_PUBLIC_NAUTILUS_BROKER_GRAPHQL_ENDPOINT,
});

const authLink = setContext(async (_, { headers }) => {
  
  return {
    headers: {
      ...headers,
      'X-GoShip-API-Key': NEXT_PUBLIC_BROKER_API_KEY,
    },
  };
});

export default new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});