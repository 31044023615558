import { AppProps } from 'next/app';
import { createContext, FC } from 'react';

import useBackendBrokerContext from '@/backend/BackendBrokerContext';
import MockBrokerContext from '@/backend/mocks/MockBrokerContext';
import MockUtilityContext from '@/backend/mocks/MockUtilityContext';
import { BrokerContext } from '@/utils/types/BrokerContext';
import { UtilityContext } from '@/utils/types/UtilityContext';

type GSAppContext = {
  broker: BrokerContext;
  utility: UtilityContext;
};

// @ts-expect-error TODO Couldn't get to some of these in
// https://plslogistics.atlassian.net/browse/GS-736
export const GSAppContext = createContext<GSAppContext>(null);

export const GSAppContextProvider: FC<AppProps> = ({ children }) => {
  const backendBrokerContext = useBackendBrokerContext();
  const brokerContext =  backendBrokerContext;
  const utilityContext = MockUtilityContext;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return (
    <GSAppContext.Provider
      value={{
        broker: brokerContext,
        utility: utilityContext,
      }}>
      {children}
    </GSAppContext.Provider>
  );
};
