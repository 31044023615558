/* eslint-disable react/jsx-no-target-blank */
import {
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  MenuDivider,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
} from "@chakra-ui/react";
import { MenuIcon } from "@heroicons/react/outline";
import cx from "classnames";

export const SectionsAndLinks = [
  {
    title: "Resources",
    links: [
      {
        label: "About Goship.com",
        url: "https://www.goship.com/about-us/",
      },
      { label: "App", url: "https://www.goship.com/resources/app/" },
      {
        label: "LTL Freight Carriers",
        url: "https://www.goship.com/carriers/",
      },
      { label: "Claims", url: "https://www.goship.com/claims" },
      {
        label: "Filing All-Risk Falvey Freight Claims",
        url: "https://www.goship.com/all-risk/",
      },
      {
        label: "Taskrabbit",
        url: "https://www.goship.com/task-rabbit/",
      },
      {
        label: "Shipping Services For All Industries",
        url: "https://www.goship.com/shipping-services-2/",
      },
      { label: "Blog", url: "https://www.goship.com/blog/" },
      { label: "News", url: "https://www.goship.com/blog/category/news/" },
    ],
  },
  {
    title: "Services",
    links: [
      {
        label: "LTL Freight",
        url: "https://www.goship.com/shipping-services/ltl-freight-shipping/",
      },
      {
        label: "Truckload Freight",
        url:
          "https://www.goship.com/shipping-services/truckload-freight-shipping/",
      },
      {
        label: "Small Business",
        url:
          "https://www.goship.com/shipping-services/small-business-shipping/",
      },
      {
        label: "Large Items",
        url: "https://www.goship.com/shipping-services/large-item-shipping/",
      },
      {
        label: "International",
        url: "https://www.goship.com/shipping-services/international-shipping/",
      },
      {
        label: "FTL Quote",
        url: "https://www.goship.com/shipping-services/quote-ftl/",
      },
      {
        label: "LTL Quote",
        url: "https://www.goship.com/shipping-services/quote-ltl/",
      },
    ],
  },
];

const HeaderLink = ({
  href,
  className,
  onClick,
  label,
  dataCyId,
}: {
  href?: string;
  onClick?: () => void;
  label: string;
  className?: string;
  dataCyId?: string;
}) => (
  <Link
    onClick={onClick}
    target="_blank"
    className={cx(className, "mr-3 text-gray-50")}
    href={href}
    data-cy={dataCyId}
  >
    <span className="text-gray-500">{label}</span>
  </Link>
);

/**
 * Only visible on screens smaller than md breakpoint (786px)
 */
const MobileMenu = () => {
  return (
    <div className="md:hidden">
      <Menu closeOnSelect={false}>
        <MenuButton>
          <MenuIcon className="h-6 w-6" />
        </MenuButton>
        <MenuList>
          {SectionsAndLinks.map((s, i) => (
            <div key={i}>
              <MenuGroup title={s.title}>
                {s.links.map(({ label, url }, j) => (
                  <MenuItem key={j}>
                    <a target="_blank" href={url}>
                      {label}
                    </a>
                  </MenuItem>
                ))}
              </MenuGroup>
              <MenuDivider />
            </div>
          ))}
          <MenuItem>
            <a target="_blank" href="https://www.goship.com/faq/">
              FAQ
            </a>
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
};
/**
 * Only visble on md breakpoint and larger
 */
const DesktopMenu = () => {
  return (
    <div className="hidden md:flex justify-end items-center flex-grow font-bold">
      {SectionsAndLinks.map((s, i) => (
        <Popover key={i} trigger="hover" placement="bottom-end">
          <PopoverTrigger>
            <span className="mr-5 text-gray-500 cursor-pointer">{s.title}</span>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <div className="p-4 pb-2">
                {s.links.map((l, j) => (
                  <div key={j} className="mb-2">
                    <a target="_blank" href={l.url}>
                      {l.label}
                    </a>
                  </div>
                ))}
              </div>
            </PopoverContent>
          </Portal>
        </Popover>
      ))}
      <HeaderLink href="https://www.goship.com/faq/" label="FAQ" />
    </div>
  );
};

export default function AppHeader() {
  return (
    <div className="bg-white px-2 py-4 flex justify-between">
      <div className="flex-shrink h-18">
        <Link href={"/"}><img src="https://www.goship.com/wp-content/uploads/2022/10/logo-dark-ship.png" alt="React Image" height="30" width="100" />LTL Rate API
         
        </Link>
      </div>
      <MobileMenu />
      <DesktopMenu />
    </div>
  );
}
