import { gql, useMutation } from "@apollo/client";

import { BookingLog } from "@/utils/types";

const PREPARE_ORDER = gql`
  mutation PrepareOrder(
    $quoteId: String!
    $orderNumber: String!
    $origin: FullEndpointInput!
    $destination: FullEndpointInput!
  ) {
    prepareOrder(
      input: {
        quoteId: $quoteId
        orderNumber: $orderNumber
        origin: $origin
        destination: $destination
      }
    ) {
      vendorOrder {
        id
      }
      error
    }
  }
`;

export default function usePrepareOrderGQL() {
  const [prepareOrderQL, { data, error, loading }] = useMutation<any>(
    PREPARE_ORDER
  );
  const prepareOrder = async (
    quoteId: string,
    orderNumber: string,
    origin: any,
    destination: any
  ) => {
    const { data } = await prepareOrderQL({
      variables: { quoteId, orderNumber, origin, destination },
    });
    return data ? data.prepareOrder : null;
  };
  return [prepareOrder, { data, error, loading }] as const;
}
