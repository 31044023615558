import cx from 'classnames';
import { FC } from 'react';

const SocialLink = ({
  src,
  alt,
  className,
  url,
}: {
  src: string;
  alt: string;
  className?: string;
  url: string;
}) => (
  <div>
    <a href={url} target="_blank" rel="noreferrer">
      <img
        className={cx('h-10 w-auto md:mb-2', className)}
        src={src}
        alt={alt}
      />
    </a>
  </div>
);

const FooterBlock: FC<{ title: string; opacity?: boolean }> = ({
  title,
  children,
  opacity = true,
}) => (
  <div className="mb-2">
    <div className="font-bold opacity-100">{title}</div>
    <div className={cx({ 'opacity-80': opacity })}>{children}</div>
  </div>
);

export default function AppFooter() {
  return (
    <>
      <div className="flex justify-around lg:flex-row flex-col items-center px-4 py-8 bg-footer-img">
        <FooterBlock title="CONTACT US">
          <div>Phone Number:</div>
          <div> 888-U-SHIPPING</div>
          <div> (888-888-8888)</div>
          <div className="mt-5"> Support Hours:</div>
          <div> Monday - Friday:</div>
          <div>8:00 AM - 5:00 PM EST </div>
        </FooterBlock>
        <FooterBlock title="VISIT SOCIAL MEDIA CHANNELS" opacity={false}>
          <div className="flex space-x-4 mt-4 justify-center">
            <SocialLink
              url="https://www.facebook.com"
              src="/icon-facebook.png"
              alt="Facebook"
            />
            <SocialLink
              url="https://twitter.com"
              src="/icon-twitter.png"
              alt="Twitter"
            />
            <SocialLink
              url="https://www.linkedin.com"
              src="/icon-linkedin.png"
              alt="LinkedIn"
            />
            <SocialLink
              url="https://www.youtube.com"
              src="/icon-youtube.png"
              alt="YouTube"
            />
          </div>
        </FooterBlock>
      </div>
      <div className="flex justify-center p-2 md:p-4">
        © {new Date().getFullYear()} GoShip. All Rights Reserved.
      </div>
    </>
  );
}
